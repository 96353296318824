import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EditButton} from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import {InputText, InputUrl} from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import {FormActionType, HTTPStatusResponse} from "../config/global";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import {useMutateWithInvalidateQueries} from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import {ImSpinner2} from "react-icons/im";
import {Select2} from "../components/form/Select";
import React from "react";

export default function SettingPage() {
    /**
     * Hooks
     */
    const {
        baseApiUrl,
        pagination,
        currentElement,
        setCurrentElement,
        openModal,
        setOpenModal,
        title,
        setActionForm,
        setTitleForm,
        setPagination,
    } = useHeaderFunctionPage({baseApiUrl: "org_setting", title: "Setting"});

    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
    ];
    const {
        register,
        reset,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm();
    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
        queryKey
    );
    const {mutateAsync: updateData, isLoading: updateIsLoading} =
        useMutateWithInvalidateQueries(
            APP_API_URL + `${baseApiUrl}-update/${currentElement}`,
            "put",
            queryKey
        );
    const handleSubmitEditForm = (values: any) => {
        updateData({...values}).then(
            (response: any) => {
                if (response && response.data.status === HTTPStatusResponse.OK) {
                    handleCancelForm();
                    toast.success("url modifié");
                }
            });
    };
    const handleEditElement = (element: any) => {
        setCurrentElement(element.id);
        setTitleForm("Modifier");
        setActionForm(FormActionType.EDIT);
        setValue("url", element.url);
        setValue("cancelUrl", element.cancel_url);
        setOpenModal(true);
    };
    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
        setCurrentElement(0);
    };
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Notif url",
            key: "url",
            dataIndex: "url",
        },
        {
            title: "Cancel url",
            key: "cancel_url",
            dataIndex: "cancel_url",
        },
        {
            title: "Code Ussd",
            key: "code_ussd",
            render: (element: any) => (
                <>#{element.code_ussd}*{element.menu}#</>
            ),
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },
        {
            title: <span style={{textAlign: "center"}}>#</span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    {updateIsLoading && currentElement === element.id ? (
                        <ImSpinner2 className="animate-spin text-red-500" size={"24px"}/>
                    ) : (
                        <EditButton onClick={() => handleEditElement(element)}/>
                    )}
                </div>
            ),
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
            <ModalForm
                title='Modifier'
                isLoading={updateIsLoading
                }
                onClose={handleCancelForm}
                onConfirm={handleSubmit(handleSubmitEditForm)}
                open={openModal}
            >
                <div className={"grid grid-cols-1 gap-4"}>
                    <div>
                        <InputUrl
                            placeholder={"Entrer l'url de notif"}
                            label={"Url de notif"}
                            register={register}
                            error={errors?.url}
                            name={"url"}
                        />
                    </div>
                    <div>
                        <InputUrl
                            placeholder={"Entrer l'url d'échec"}
                            label={"Url d'échec"}
                            register={register}
                            error={errors?.cancelUrl}
                            name={"cancelUrl"}
                        />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
