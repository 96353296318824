import { UserRoles } from "./global"
import {BiSolidArchive, BiSolidSortAlt} from "react-icons/bi";

export interface MenuInterface {
    icon: React.FC;
    title: string;
    link: string;
    pathname: string;
    roles?: UserRoles[];
    subMenus?: MenuInterface[];
  }
  
export const MENUS: MenuInterface[] = [
    {
        icon: BiSolidArchive,
        title: "Session",
        link: "/sessions",
        pathname: "sessions"
    },
    {
        icon: BiSolidSortAlt,
        title: "Setting",
        link: "/setting",
        pathname: "setting"
    },
]
