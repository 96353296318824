import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import {useQueries} from "../hooks/useQueries";
import {useRef} from "react";
import moment from "moment/moment";

export default function SessionPage() {
    /**
     * Hooks
     */
    const {
        baseApiUrl,
        pagination,
        title,
        setPagination,
    } = useHeaderFunctionPage({baseApiUrl: "session", title: "Sessions"});

    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
    ];
    const {
        register,
        reset,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();
    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}`,
        queryKey
    );
    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Id",
            key: "session_id",
            dataIndex: "session_id",
        },
        {
            title: "Téléphone",
            key: "phone_number",
            dataIndex: "phone_number",
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },
    ];
    /**
     * UI
     */
    return (
        <>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    );
}
